import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

import { menuConfig } from '../configs';
import { NavLink } from './NavLink';

export const MenuBar = (props?: FlexProps) => {
  return (
    <Flex justifyContent="space-around" alignItems="center" {...props}>
      {menuConfig.map((m, idx) => (
        <NavLink
          path={m.href}
          activePathNames={m.activePathNames}
          key={idx}
          icon={m.icon}
          title={m.title}
          activeIcon={m.activeIcon}
        />
      ))}
    </Flex>
  );
};
