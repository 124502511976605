import { getAppUrl } from '@ui/utils';
import merge from 'lodash-es/merge';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import type { NextSeoProps } from 'next-seo/lib/types';

const SITE_NAME = process.env.APP_DOMAIN;
const BASE_URL = getAppUrl();
const AUTHOR = '@CronosApp';

type SEOTemplateProps = {
  title: string;
  locale?: string;
  path?: string;
  description: string;
  moreConfig?: NextSeoProps;
  textLimit?: number;
};

const defaultSEOConfig: NextSeoProps = {
  noindex: false,
  nofollow: false,
  openGraph: {
    site_name: SITE_NAME,
    type: 'website',
    article: {
      authors: [AUTHOR],
    },
  },
  twitter: {
    handle: AUTHOR,
    site: AUTHOR,
    cardType: 'summary',
  },
};

export const SEOTemplate = ({
  locale,
  path,
  title,
  description,
  moreConfig = {},
  textLimit = 300,
}: SEOTemplateProps) => {
  const { asPath } = useRouter();
  // Remove the query in asPath
  path = path ?? asPath.split('?')[0];
  const url = `${BASE_URL}${path}`;

  const languageAlternates = [
    { hrefLang: 'en', href: url },
    { hrefLang: 'x-default', href: url },
  ];

  description =
    description.length > textLimit
      ? description.slice(0, textLimit) + '...'
      : description;

  const customConfig: NextSeoProps = {
    title,
    titleTemplate: title,
    description,
    canonical: url,
    languageAlternates,
    openGraph: {
      locale,
      url,
      title: title,
      description,
    },
  };
  const seoConfig = merge({}, defaultSEOConfig, customConfig, moreConfig);
  return <NextSeo {...seoConfig} />;
};
