import { useState } from 'react';
import { useMount } from 'react-use';

/**
 * prevent the not-matched error of hydration
 */
export const useMountedValue = <T>(value: T): T | undefined => {
  const [isMounted, setIsMounted] = useState(false);
  useMount(() => {
    setIsMounted(true);
  });
  return isMounted ? value : undefined;
};
