import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

import { BOTTOM_MENU_BAR_HEIGHT } from '../constants';
import { MenuBar } from './MenuBar';

export type BottomMenuBarProps = FlexProps;
export const BottomMenuBar: React.FC<BottomMenuBarProps> = (props) => {
  return (
    <Flex
      position="fixed"
      zIndex="overlay"
      bottom={0}
      justify="space-around"
      width="full"
      height={`${BOTTOM_MENU_BAR_HEIGHT}px`}
      backgroundColor={'primary.layer0'}
      px={{
        lg: '32px',
        base: '16px',
      }}
      {...props}
    >
      <MenuBar flex={1} />
    </Flex>
  );
};
