import {
  Amply,
  Dashboard,
  DashboardFill,
  Home,
  HomeFill,
  LandingBlockBorder,
  Markets,
  MarketsFill,
} from '@amply-app/icons';
import { currentWallet } from '@amply-app/wallet';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChakraNextLink } from '@ui/components/ChakraNextLink';
import { useSupplyAndBorrowModal } from '@ui/components/SupplyAndBorrowModal';
import { communityList } from '@ui/views/Landing/Introduction';
import { useRouter } from 'next/router';

const HOME_PATH = '/';
const NavBtns = [
  { label: 'Home', icon: Home, activeIcon: HomeFill, link: HOME_PATH },
  {
    label: 'Markets',
    icon: Markets,
    activeIcon: MarketsFill,
    link: '/markets',
  },
  {
    label: 'Dashboard',
    icon: Dashboard,
    activeIcon: DashboardFill,
    link: '/dashboard',
  },
  {
    label: 'Amply',
    icon: Amply,
    activeIcon: Amply,
    link: '/amply',
  },
];
const Sidebar = () => {
  const pathname = useRouter().pathname;
  const isConnected = currentWallet.useIsConnected();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const openSupplyAndBorrowModal = useSupplyAndBorrowModal((s) => s.openModal);
  return (
    <Box width={'300px'} mt={11} ml={-4}>
      <Box pos={'fixed'}>
        <Flex direction={'column'} justifyItems={'center'}>
          <VStack align={'flex-start'} ml={'16px'}>
            {NavBtns.map(({ label, icon, activeIcon, link }) => {
              const isActive =
                link === HOME_PATH
                  ? pathname === link
                  : pathname.startsWith(link);
              return (
                <ChakraNextLink href={link} key={label}>
                  <Center
                    color={'primary.cta'}
                    h={'44px'}
                    textStyle={'body2'}
                    {...(isActive && {
                      textStyle: 'h4',
                      borderLeft: '1px solid',
                      borderLeftColor: 'primary.cta',
                    })}
                  >
                    <Icon
                      as={isActive ? activeIcon : icon}
                      color={label === 'Amply' ? 'black' : ''}
                      ml={2}
                      mr={3}
                      boxSize={6}
                    />
                    {label}
                  </Center>
                </ChakraNextLink>
              );
            })}
          </VStack>
          <Box pos={'relative'} mt={8}>
            <Button
              w={'171px'}
              onClick={() => {
                if (isWrongNetwork || !isConnected) return;
                openSupplyAndBorrowModal();
              }}
            >
              {isWrongNetwork
                ? 'Switch network'
                : isConnected
                ? 'Supply / Borrow'
                : 'Connect wallet'}
            </Button>
            <Icon
              as={LandingBlockBorder}
              pos={'absolute'}
              w={'197.5px'}
              h={'96.25px'}
              color={'primary.active_state'}
              left={'-13px'}
              top={'24px'}
              transform={'rotate(180deg)'}
            />
          </Box>
        </Flex>
        <Text textStyle={'body3'} color={'primary.cta'} mt={28}>
          Enter the fold
        </Text>
        <Flex gap={6} mt={6}>
          {communityList.map(({ icon, link }) => (
            <Link href={link} key={link} isExternal>
              <Icon as={icon} color="primary.cta" w="6" h="6" />
            </Link>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default Sidebar;
