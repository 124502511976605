import { AmplyLogo, AmplyLogoText, Onboarding } from '@amply-app/icons';
import { Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import { useOnboardingModal } from '@ui/components/OnboardingModal/useOnboardingModal';
import {
  MAX_WIDTH,
  TOP_NAV_HEIGHT,
  TOP_NAV_HEIGHT_DESKTOP,
} from '@ui/config/ui';
import { useMountedValue } from '@ui/hooks/useMountedValue';
import NextLink from 'next/link';
import React from 'react';
import { useWindowScroll } from 'react-use';

import { WalletNavItem } from './WalletNavItem';

export const TopNavbar = () => {
  const isStuck = useMountedValue(useWindowScroll().y >= 20);
  const openModal = useOnboardingModal((s) => s.openModal);

  return (
    <Flex
      h={{
        base: `${TOP_NAV_HEIGHT}px`,
        desktop: `${TOP_NAV_HEIGHT_DESKTOP}px`,
      }}
      position="sticky"
      top="0"
      left="0"
      zIndex="docked"
      align="center"
      justifyContent="center"
      px="16px"
      bgColor={isStuck ? 'primary.backgroundAlt' : undefined}
      transition="background-color .1s"
    >
      <HStack maxW={`${MAX_WIDTH}px`} justify="space-between" flex="1 1 auto">
        <NextLink href="/">
          <HStack gap="0">
            <Icon
              color="primary.cta"
              as={AmplyLogo}
              h={{
                base: '32px',
                desktop: '30px',
              }}
              w={{
                base: '57px',
                desktop: '67px',
              }}
            />
            <InDesktop>
              <Icon
                color="primary.cta"
                as={AmplyLogoText}
                w={'154px'}
                h={'38px'}
                ml={'6px'}
              />
            </InDesktop>
          </HStack>
        </NextLink>
        <HStack>
          <Flex flexDir={'column'} alignItems={'center'}>
            <Icon
              role="button"
              as={Onboarding}
              color={'primary.cta'}
              boxSize="40px"
              onClick={openModal}
              p="6px"
            />
            <InDesktop>
              <Text textStyle={'captionBold'} color={'primary.cta'}>
                Learn
              </Text>
            </InDesktop>
          </Flex>
          <WalletNavItem />
        </HStack>
      </HStack>
    </Flex>
  );
};
