import { currentWallet } from '@amply-app/wallet';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useAccountModal } from '@ui/components/AccountModal/useAccountModal';
import { CurrentWalletIcon } from '@ui/components/CurrentWalletIcon';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import { useWalletModal } from '@ui/components/Wallet';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';

export const WalletNavItem = () => {
  const openWalletModal = useWalletModal((s) => s.openModal);
  const openAccountModal = useAccountModal((s) => s.openModal);
  const isConnected = currentWallet.useIsConnected();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const switchNetworkMutation = useSwitchNetwork();

  if (isWrongNetwork) {
    return (
      <Button
        variant="ghost"
        role="button"
        color="primary.error"
        textStyle="body1Bold"
        onClick={() => {
          switchNetworkMutation.mutateAsync();
        }}
      >
        Wrong network
      </Button>
    );
  }

  if (isConnected) {
    return (
      <Flex flexDir={'column'} alignItems={'center'}>
        <Button variant="ghost" p="4px" onClick={openAccountModal}>
          <CurrentWalletIcon />
        </Button>
        <InDesktop>
          <Text textStyle={'captionBold'} color={'primary.cta'}>
            Wallet
          </Text>
        </InDesktop>
      </Flex>
    );
  }

  return (
    <Button
      variant="ghost"
      color="primary.cta"
      textStyle={{
        base: 'cta',
        desktop: 'caption_bold',
      }}
      onClick={openWalletModal}
    >
      Connect wallet
    </Button>
  );
};
