import { currentWallet } from '@amply-app/wallet';
import { Box, Button, Divider, Flex } from '@chakra-ui/react';
import { MAX_WIDTH } from '@ui/config/ui';
import { type ReactNode } from 'react';

import { InDesktop, InMobile } from '../MobileOrDesktop';
import { useSupplyAndBorrowModal } from '../SupplyAndBorrowModal';
import { BottomMenuBar } from './BottomMenuBar';
import { BOTTOM_MENU_BAR_HEIGHT } from './constants';
import {
  StyledAppBackground,
  StyledAppBackgroundDesktop,
} from './StyledBackground';
import { TopNavbar } from './TopNavBar';

interface LayoutProps {
  isFullWidth?: boolean;
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ isFullWidth, children }) => {
  const openSupplyAndBorrowModal = useSupplyAndBorrowModal((s) => s.openModal);
  const isConnected = currentWallet.useIsConnectedToSupportedChain();
  return (
    <Flex
      direction={'column'}
      minH={'100vh'}
      backgroundColor={'primary.background'}
      paddingBottom={{
        base: `${BOTTOM_MENU_BAR_HEIGHT + 20}px`,
        lg: 0,
      }}
    >
      <InMobile>
        <StyledAppBackground />
      </InMobile>
      <InDesktop>
        <StyledAppBackgroundDesktop />
      </InDesktop>
      <TopNavbar />
      <Flex
        as="main"
        className="ContentContainer"
        direction="column"
        flex="1 1 0"
        zIndex={1}
        mx="auto"
        maxW={`${MAX_WIDTH}px`}
        px={isFullWidth ? 0 : 4}
      >
        {children}
      </Flex>
      {isConnected && (
        <InMobile>
          <Button
            w="70px"
            h="70px"
            variant={'fab'}
            bottom={`${BOTTOM_MENU_BAR_HEIGHT + 10}px`}
            right={4}
            onClick={openSupplyAndBorrowModal}
            zIndex={1}
          >
            <Box position={'absolute'}>
              Supply
              <Divider h="1px" mb="4px" mt="4px" borderColor="primary.layer1" />
              Borrow
            </Box>
          </Button>
        </InMobile>
      )}
      <InMobile>
        <BottomMenuBar />
      </InMobile>
    </Flex>
  );
};
