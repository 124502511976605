import {
  Amply,
  Dashboard,
  DashboardFill,
  Markets,
  MarketsFill,
} from '@amply-app/icons';
import type { SVGProps } from 'react';

export const menuConfig: {
  href: string;
  title: string;
  activePathNames?: string[];
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  activeIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}[] = [
  {
    href: '/dashboard',
    title: 'Dashboard',
    icon: Dashboard,
    activeIcon: DashboardFill,
  },
  {
    href: '/markets',
    title: 'Markets',
    icon: Markets,
    activeIcon: MarketsFill,
    // activePathNames: ['/markets/supply', '/markets/borrow'],
  },
  {
    href: '/amply',
    title: 'Amply',
    icon: Amply,
    activeIcon: Amply,
  },
];
