import { Box } from '@chakra-ui/react';
import { TOP_NAV_HEIGHT } from '@ui/config/ui';

export const StyledAppBackground = () => {
  return (
    <>
      <Box
        position={'fixed'}
        width="100%"
        height={`calc(100vh - ${TOP_NAV_HEIGHT}px)`}
        background="url('/images/bg.svg') no-repeat center center fixed"
        filter={'blur(40px)'}
        top={`${TOP_NAV_HEIGHT}px`}
      />
      <Box
        position={'fixed'}
        width="100%"
        height="100%"
        opacity={'.75'}
        background="primary.backgroundAlt"
        mixBlendMode={'multiply'}
      />
      <Box
        position={'fixed'}
        width="50%"
        height="50%"
        maxH={'424px'}
        maxW={'415px'}
        border-radius="424px"
        background="#7A3CFF"
        filter="blur(250px)"
        top={'10%'}
        left={'25%'}
      />
      <Box
        position={'fixed'}
        width="100%"
        height="100%"
        opacity={'.5'}
        background="url('/images/bg-noise.svg')"
        mixBlendMode={'overlay'}
      />
    </>
  );
};

export const StyledAppBackgroundDesktop = () => {
  return (
    <>
      <Box
        position={'fixed'}
        width="100%"
        height={`calc(100vh - ${TOP_NAV_HEIGHT}px)`}
        background="url('/images/bg-desktop.svg') no-repeat top center fixed"
        filter={'blur(40px)'}
        top={`${TOP_NAV_HEIGHT}px`}
      />
      <Box
        position={'fixed'}
        width="100%"
        height="100%"
        opacity={'.75'}
        background="primary.backgroundAlt"
        mixBlendMode={'multiply'}
      />
      <Box
        position={'fixed'}
        width="30%"
        height="20vw"
        maxH={'895.5px'}
        maxW={'876.49px'}
        border-radius="424px"
        background="#7A3CFF"
        filter="blur(1000px)"
        top={'20vh'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
      <Box
        position={'fixed'}
        width="100%"
        height="100%"
        opacity={'.5'}
        background="url('/images/bg-noise-desktop.svg')"
        mixBlendMode={'overlay'}
      />
    </>
  );
};
